.slick-list {
  height: 100vh !important;
}

.slick-slide {
  margin-top: -10px !important;
}

.ant-modal-header {
  padding: 8px 12px !important;
  text-align: center;
}

.ant-modal-close-x {
  line-height: 40px !important;
}
